import React, { useState } from 'react'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'

const MyNavbar = props => {
  const [collapsed, setCollapsed] = useState(true)

  const toggleNavbar = () => setCollapsed(!collapsed)

  return (
    <Navbar color='dark' dark expand='md' fixed='top'>
      <NavbarBrand href='/' className='mr-auto'>Kweezy & Co.</NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} className='mr-2' />
      <Collapse isOpen={!collapsed} navbar>
        <Nav navbar className='ml-auto'>
          <NavItem>
            <Link to='/' className='nav-link'>Portfolio</Link>
          </NavItem>
          <NavItem>
            <Link to='/options' className='nav-link'>Options</Link>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default MyNavbar

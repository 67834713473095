import React, { useState } from 'react'
import { Button } from 'reactstrap'

import { formatNumericAmount } from '../lib/numbers'
import ShareLotsTable from '../components/ShareLotsTable'

const AggregatedShareLotRow = ({ aggregatedLot, quotes }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (!aggregatedLot || !quotes) {
    return null
  }

  const {
    cost,
    lots,
    quantity,
    symbol
  } = aggregatedLot

  const costBasis = quantity * cost

  const price = quotes[symbol] || aggregatedLot.price
  const value = price * quantity
  const dollarReturn = value - costBasis
  const percentReturn = dollarReturn / costBasis

  return (
    <>
      <tr>
        <td>{symbol}</td>
        <td>{formatNumericAmount(quantity, 2)}</td>
        <td>${formatNumericAmount(cost)}</td>
        <td>${formatNumericAmount(costBasis)}</td>
        <td>${formatNumericAmount(price)}</td>
        <td>${formatNumericAmount(value)}</td>
        <td>${formatNumericAmount(dollarReturn, 2, true)}</td>
        <td>{formatNumericAmount(percentReturn * 100, 2, true)}%</td>
        <td>
          <Button onClick={() => setIsExpanded(!isExpanded)} size='sm'>
            {isExpanded ? 'Hide' : 'Show'} Lots
          </Button>
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colspan='9'>
            <ShareLotsTable quotes={quotes} shareLots={lots} />
          </td>
        </tr>
      )}
    </>
  )
}

export default AggregatedShareLotRow

import React, { Fragment, useEffect, useState } from 'react'
import { Button, Input, Table } from 'reactstrap'

import { getOptionExpirationsAndStrikes, getOptionChain } from '../lib/firebase'

export default function OptionsScreen () {
  const [symbol, setSymbol] = useState('')
  const [selectedSymbol, setSelectedSymbol] = useState(null)
  const [expirations, setExpirations] = useState(null)

  useEffect(() => {
    const fetchSymbolData = async symbol => {
      const data = await getOptionExpirationsAndStrikes(symbol)

      setExpirations(data)
    }

    if (selectedSymbol) {
      fetchSymbolData(selectedSymbol)
    }
  }, [selectedSymbol])

  const fetchOptionChain = async (expiration, idx) => {
    const data = await getOptionChain(selectedSymbol, expiration.date)

    const newExpiration = { ...expiration }

    newExpiration.strikes = data
    newExpiration.calls = data.filter(x => x.type === 'call')
    newExpiration.puts = data.filter(x => x.type === 'put')

    const newExpirations = [...expirations]

    newExpirations.splice(idx, 1, newExpiration)

    setExpirations(newExpirations)
  }

  return (
    <>
      <Input type='text' value={symbol} onChange={e => setSymbol(e.target.value)} placeholder='Enter a symbol, e.g. TSLA' />
      <Button onClick={() => setSelectedSymbol(symbol)} style={{ marginTop: '1rem' }}>Fetch Data</Button>

      {expirations && (
        <Table>
          <thead>
            <tr>
              <th>Expiration Date</th>
            </tr>
          </thead>
          <tbody>
            {expirations.map((expiration, idx) => (
              <Fragment key={idx}>
                <tr>
                  <td>{expiration.date} <Button style={{ marginLeft: 15 }} onClick={() => fetchOptionChain(expiration, idx)}>Fetch Option Chain</Button></td>

                </tr>
                {expiration.calls && expiration.puts && (
                  <tr>
                    <Table>
                      <thead>
                        <tr>
                          <th colspan={3} style={{ textAlign: 'center' }}>Calls</th>
                          <th />
                          <th colspan={3} style={{ textAlign: 'center' }}>Puts</th>
                        </tr>
                        <tr>
                          <th>Last</th>
                          <th>IV</th>
                          <th>MV</th>
                          <th>Strike</th>
                          <th>Last</th>
                          <th>IV</th>
                          <th>MV</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expiration.strikes.map((strikeData, idx) => (
                          <tr key={idx}>
                            <td>{strikeData.call.last}</td>
                            <td>{strikeData.call.iv}</td>
                            <td>{strikeData.call.mv}</td>
                            <td>{strikeData.call.strike}</td>
                            <td>{strikeData.put.last}</td>
                            <td>{strikeData.put.iv}</td>
                            <td>{strikeData.put.mv}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

import React from 'react'
import { Table } from 'reactstrap'
import * as moment from 'moment'

import { formatNumericAmount } from '../lib/numbers'

const ShareLotsTable = ({ quotes, shareLots }) => {
  if (!shareLots || !quotes) {
    return null
  }

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Account</th>
          <th>Acquisition Date</th>
          <th># Shares</th>
          <th>Cost / Share</th>
          <th>Cost Basis</th>
          <th>Price</th>
          <th>Value</th>
          <th>$ Return</th>
          <th>% Return</th>
          <th>Post Tax Value</th>
          <th>Post Tax % Return</th>
          <th>Break Even Price</th>
          <th>Break Even % Change</th>
        </tr>
      </thead>
      <tbody>
        {shareLots.sort((a, b) => a.symbol < b.symbol ? -1 : 1).map(shareLot => {
          const {
            account,
            acquisitionDate,
            cost,
            id,
            quantity,
            symbol
          } = shareLot

          const costBasis = quantity * cost

          const price = quotes[symbol] || shareLot.price
          const value = price * quantity
          const dollarReturn = value - costBasis
          const percentReturn = dollarReturn / costBasis

          const shortTermTaxRate = 0.37
          const longTermTaxRate = 0.2

          // If the share lot was purchased in the past year, then it is short term gains
          const isShortTermGains = moment(acquisitionDate).add(1, 'year').isAfter(moment())

          const taxRate = isShortTermGains ? shortTermTaxRate : longTermTaxRate

          const tax = dollarReturn > 0 ? dollarReturn * taxRate : 0

          const postTaxValue = value - tax
          const postTaxPercentReturn = (postTaxValue - costBasis) / costBasis

          const breakEvenPercentChange = (postTaxValue - value) / value
          const breakEvenPrice = price + (price * breakEvenPercentChange)

          return (
            <tr key={id}>
              <td>{symbol}</td>
              <td>{account.name}</td>
              <td>{moment(acquisitionDate).format('MMM Do, YYYY')}</td>
              <td>{formatNumericAmount(quantity)}</td>
              <td>${formatNumericAmount(cost)}</td>
              <td>${formatNumericAmount(costBasis)}</td>
              <td>${formatNumericAmount(price)}</td>
              <td>${formatNumericAmount(value)}</td>
              <td>${formatNumericAmount(dollarReturn, 2, true)}</td>
              <td>{formatNumericAmount(percentReturn * 100, 2, true)}%</td>
              <td>${formatNumericAmount(postTaxValue)}</td>
              <td>{formatNumericAmount(postTaxPercentReturn * 100, 2, true)}%</td>
              <td>${formatNumericAmount(breakEvenPrice)}</td>
              <td>{formatNumericAmount(breakEvenPercentChange * 100, 2, true)}%</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ShareLotsTable

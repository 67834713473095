import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import { firebaseConfig } from './config'

firebase.initializeApp(firebaseConfig)

if (window.location.hostname.includes('localhost')) {
  firebase.functions().useFunctionsEmulator('http://localhost:5001')
}

export const firestore = firebase.firestore()

export const firestoreCollections = {
  accounts: firestore.collection('accounts'),
  shareLots: firestore.collection('share_lots')
}

export const getOptionExpirationsAndStrikes = async symbol => {
  const func = firebase.functions().httpsCallable('options-getExpirationsAndStrikes')
  const res = await func(symbol)
  return res.data
}

export const getOptionChain = async (symbol, expiration) => {
  const func = firebase.functions().httpsCallable('options-getChain')
  const res = await func({ symbol, expiration })
  return res.data
}

export const getQuotes = async symbols => {
  if (!symbols.length) {
    return []
  }

  const func = firebase.functions().httpsCallable('quotes-getQuotes')
  const res = await func(Array.from(new Set(symbols)))
  return res.data
}

export const getUserShareLots = userId =>
  getAll(firestoreCollections.shareLots.where('userId', '==', userId))

export const getUserAccounts = userId =>
  getAll(firestoreCollections.accounts.where('userId', '==', userId))

export default firebase

const getAll = async ref => {
  const query = await ref.get()
  return query.docs.map(x => x.data())
}

import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'

import { getQuotes, getUserAccounts, getUserShareLots } from '../lib/firebase'
import useAuth from '../hooks/auth'
import AddAccountModal from '../components/AddAccountModal'
import AddShareLotModal from '../components/AddShareLotModal'
import AggregatedShareLotRow from '../components/AggregatedShareLotRow'
import { formatNumericAmount } from '../lib/numbers'

const PortfolioScreen = () => {
  const [accounts, setAccounts] = useState(null)
  const [aggregatedLots, setAggregatedLots] = useState(null)
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false)
  const [isAddShareLotModalOpen, setIsAddShareLotModalOpen] = useState(false)
  const [quotes, setQuotes] = useState(null)
  const [shareLots, setShareLots] = useState(null)
  const [value, setValue] = useState(null)
  const { user } = useAuth()

  useEffect(() => {
    const fetchData = async symbol => {
      const previouslyAddedAccounts = await getUserAccounts(user.uid)
      const previouslyAddedShareLots = await getUserShareLots(user.uid)
      const newQuotes = await getQuotes(previouslyAddedShareLots.map(x => x.symbol))

      computeAndSetValue(previouslyAddedShareLots, newQuotes)

      setAccounts(previouslyAddedAccounts)
      setQuotes(newQuotes)
      setShareLots(formatShareLots(previouslyAddedShareLots, previouslyAddedAccounts))
    }

    if (user) {
      fetchData()
    }
  }, [user])

  useEffect(() => {
    if (shareLots) {
      const lotsBySymbol = {}

      shareLots.forEach(shareLot => {
        const {
          cost,
          id,
          quantity,
          symbol
        } = shareLot

        if (!lotsBySymbol[symbol]) {
          lotsBySymbol[symbol] = {
            cost: 0,
            id,
            lots: [],
            price: 0,
            quantity: 0,
            symbol
          }
        }
        const aggregatedLot = lotsBySymbol[symbol]

        aggregatedLot.lots.push(shareLot)

        const existingCost = aggregatedLot.cost * aggregatedLot.quantity
        const costToAdd = cost * quantity

        aggregatedLot.cost = (existingCost + costToAdd) / (aggregatedLot.quantity + quantity)
        aggregatedLot.quantity += quantity
        aggregatedLot.price = quotes[symbol] || shareLot.price
      })

      computeAndSetValue(shareLots, quotes)

      setAggregatedLots(Object.values(lotsBySymbol))
    } else {
      setAggregatedLots(null)
    }
  }, [shareLots])

  useEffect(() => {
    computeAndSetValue(shareLots, quotes)
  }, [quotes])

  const computeAndSetValue = (shareLots, quotes) => {
    if (shareLots && quotes) {
      setValue(
        shareLots.reduce((acc, shareLot) => {
          const { symbol, quantity } = shareLot
          const price = quotes[symbol] || shareLot.price

          acc += (quantity * price)

          return acc
        }, 0)
      )
    }
  }

  const refreshQuotes = async () => {
    const newQuotes = await getQuotes(Object.keys(quotes))
    setQuotes(newQuotes)
  }

  const addAccount = async account => {
    const newAccounts = [...(accounts || [])]

    newAccounts.push(account)

    setAccounts(newAccounts)
  }

  const addShareLot = async shareLot => {
    const newShareLots = [...(shareLots || [])]

    newShareLots.push(shareLot)

    if (!quotes[shareLot.symbol]) {
      const newQuotes = await getQuotes(newShareLots.map(x => x.symbol))

      setQuotes(newQuotes)
    }

    setShareLots(
      formatShareLots(newShareLots, accounts)
    )
  }

  const formatShareLots = (shareLots, accounts) => {
    const newShareLots = [...shareLots].map(shareLot => ({
      ...shareLot,
      account: accounts.find(acc => acc.id === shareLot.accountId)
    }))

    newShareLots.sort((a, b) => {
      if (a.symbol < b.symbol) {
        return -1
      } else if (a.symbol > b.symbol) {
        return 1
      } else {
        if (a.acquisitionDate < b.acquisitionDate) {
          return -1
        } else {
          return 1
        }
      }
    })

    return newShareLots
  }

  return (
    <>
      <h4>
        Portfolio {typeof value === 'number' && `- $${formatNumericAmount(value, 2, true)}`}
        <Button
          onClick={() => setIsAddShareLotModalOpen(true)}
          style={{ float: 'right' }}
          size='sm'
          disabled={isAddShareLotModalOpen || isAddAccountModalOpen}
        >
          Add Share Lot
        </Button>
        <Button
          onClick={() => setIsAddAccountModalOpen(true)}
          style={{ float: 'right', marginRight: '1rem' }}
          size='sm'
          disabled={isAddShareLotModalOpen || isAddAccountModalOpen}
        >
          Add Account
        </Button>
        <Button
          onClick={refreshQuotes}
          style={{ float: 'right', marginRight: '1rem' }}
          size='sm'
        >
          Refresh Quotes
        </Button>
      </h4>

      {
        shareLots && quotes && aggregatedLots
          ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th># Shares</th>
                  <th>Cost / Share</th>
                  <th>Cost Basis</th>
                  <th>Price</th>
                  <th>Value</th>
                  <th>$ Return</th>
                  <th>% Return</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {aggregatedLots.sort((a, b) => a.symbol < b.symbol ? -1 : 1).map(lot =>
                  <AggregatedShareLotRow key={lot.id} aggregatedLot={lot} quotes={quotes} />
                )}
              </tbody>
            </Table>
          )
          : <div>Loading...</div>
      }
      {accounts && (
        <AddShareLotModal
          accounts={accounts}
          addShareLot={addShareLot}
          close={() => setIsAddShareLotModalOpen(false)}
          isOpen={isAddShareLotModalOpen}
        />
      )}
      <AddAccountModal
        addAccount={addAccount}
        close={() => setIsAddAccountModalOpen(false)}
        isOpen={isAddAccountModalOpen}
      />
    </>
  )
}

export default PortfolioScreen

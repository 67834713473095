import React, { useState } from 'react'
import { Button, FormGroup, Label } from 'reactstrap'
import * as moment from 'moment'
import { v4 } from 'uuid'
import Select from 'react-select'

import useAuth from '../hooks/auth'
import Modal from './GenericModal'
import Input from './Input'
import { firestoreCollections } from '../lib/firebase'

const uuid = v4

const isDate = date => {
  if (!moment(date, 'YYYY-MM-DD').isValid()) {
    return 'Invalid date'
  }
}

const isPositive = num => {
  if (!num || Number(num) <= 0) {
    return 'Must be a positive number'
  }
}

const AddShareLotModal = ({ accounts = [], addShareLot, close, isOpen }) => {
  const [acquisitionDate, setAcquisitionDate] = useState('')
  const [costBasis, setCostBasis] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [quantity, setQuantity] = useState('')
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [symbol, setSymbol] = useState('')

  const isDisabled = !acquisitionDate || !costBasis || !quantity || !selectedAccount || !symbol

  const closeModal = () => {
    clearData()
    close()
  }

  const clearData = () => {
    setAcquisitionDate('')
    setCostBasis('')
    setIsSaving(false)
    setQuantity('')
    setSelectedAccount(null)
    setSymbol('')
  }

  const { user } = useAuth()

  const createShareLot = async () => {
    if (isDisabled || !user) {
      return
    }

    setIsSaving(true)

    const data = {
      accountId: selectedAccount.value,
      acquisitionDate,
      cost: Number(costBasis) / Number(quantity),
      id: uuid(),
      quantity: Number(quantity),
      symbol,
      userId: user.uid
    }

    await firestoreCollections.shareLots.doc(data.id).set(data)

    await addShareLot(data)
    closeModal()
  }

  const accountOptions = [
    { label: 'Select an account', value: null },
    ...accounts.sort((a, b) => a.name < b.name ? -1 : 1)
      .map(acc => ({ label: acc.name, value: acc.id }))
  ]

  return (
    <Modal close={close} header='Add a Share Lot' isOpen={isOpen}>
      <FormGroup>
        <Label>Account</Label>
        <Select
          defaultValue={accountOptions[0]}
          options={accountOptions}
          onChange={setSelectedAccount}
          disabled={isSaving}
        />
      </FormGroup>
      <Input
        label='Symbol'
        required
        formatter={v => v.toUpperCase().replace(/[^A-Z0-9]/g, '')}
        unformatter={v => v.toUpperCase().replace(/[^A-Z0-9]/g, '')}
        value={symbol}
        onChange={setSymbol}
        disabled={isSaving}
      />
      <Input
        label='Acquisition Date (YYYY-MM-DD)'
        required
        value={acquisitionDate}
        onChange={setAcquisitionDate}
        validators={[isDate]}
        disabled={isSaving}
        formatter={v => v.replace(/[^0-9\-]/g, '')}
        unformatter={v => v.replace(/[^0-9\-]/g, '')}
      />
      <Input
        label='Quantity'
        required
        value={quantity}
        onChange={setQuantity}
        validators={[isPositive]}
        disabled={isSaving}
        formatter={v => v.replace(/[^0-9\.]/g, '')}
        unformatter={v => v.replace(/[^0-9\.]/g, '')}
      />
      <Input
        label='Total Cost Basis'
        required
        value={costBasis}
        onChange={setCostBasis}
        validators={[isPositive]}
        disabled={isSaving}
        formatter={v => v.replace(/[^0-9\.]/g, '')}
        unformatter={v => v.replace(/[^0-9\.]/g, '')}
      />
      <Button
        disabled={isDisabled || isSaving}
        style={{ marginTop: '1rem' }}
        onClick={createShareLot}
      >
        Save
      </Button>
    </Modal>
  )
}

export default AddShareLotModal

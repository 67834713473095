import * as moment from 'moment'

export function onlyNums (value) {
  return value.replace(/[^0-9.]/g, '')
}

export function formatNumericAmount (
  number,
  decimals = 2,
  allowZero = false
) {
  let returnVal = ''

  if (!number && !allowZero) {
    returnVal = ''
  } else {
    returnVal = number.toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    })
  }

  return returnVal
}

export function computeFutureValue (initialAmount, periodRateOfReturn, numPeriods, periodContribution = 0) {
  const returnRate = 1 + periodRateOfReturn
  const standardPiece = initialAmount * Math.pow(returnRate, numPeriods)
  const contributionsPiece = periodContribution * (Math.pow(returnRate, numPeriods + 1) - returnRate) / periodRateOfReturn
  return standardPiece + contributionsPiece
}

/**
 * This function takes an amount to add to the balances, as well as the current balances, and outputs
 * an object containing the updates balances
 * @param surplus
 * @param retirementContribution
 * @param healthSavingsContribution
 * @param savingsBalance
 * @param investmentBalance
 * @param retirementBalance
 * @param healthSavingsBalance
 */
export function computeBalances (surplus, retirementContribution, healthSavingsContribution, savingsBalance, investmentBalance, retirementBalance, healthSavingsBalance) {
  if (surplus < 0) {
    // First subtract from savings, then investments, then retirement, then hsa
    // Retirement is 10% penalty, HSA is 20%, that's why HSA last
    savingsBalance += surplus
    retirementBalance += retirementContribution
    healthSavingsBalance += healthSavingsContribution

    if (savingsBalance < 0) {
      investmentBalance += savingsBalance
      savingsBalance = 0

      if (investmentBalance < 0) {
        retirementBalance += investmentBalance
        investmentBalance = 0

        if (retirementBalance < 0) {
          healthSavingsBalance += retirementBalance
          retirementBalance = 0

          // Health savings can't go below 0, so make it negative savings instead
          if (healthSavingsBalance < 0) {
            savingsBalance += healthSavingsBalance

            healthSavingsBalance = 0
          }
        }
      }
    }
  }

  return {
    healthSavingsBalance,
    investmentBalance,
    retirementBalance,
    savingsBalance
  }
}
export function computeMonthlyPayment (initialPrincipal, periodInterestRate, numPeriods, isInterestOnly) {
  if (isInterestOnly) {
    return initialPrincipal * periodInterestRate
  }

  const numerator = initialPrincipal * (periodInterestRate * Math.pow(1 + periodInterestRate, numPeriods))
  const denominator = Math.pow(1 + periodInterestRate, numPeriods) - 1

  return numerator / denominator
}

export function computeMortgageSplits (outstandingPrincipal, monthlyPayment, periodInterestRate) {
  const interest = outstandingPrincipal * periodInterestRate
  const principal = monthlyPayment - interest

  return {
    interest,
    principal
  }
}

export function getAmortizationSchedule (startDate, initialPrincipal, annualInterestRate, numYears, isInterestOnly) {
  const numPeriods = numYears * 12
  const periodInterestRate = annualInterestRate / 12

  let monthlyPayment = computeMonthlyPayment(initialPrincipal, periodInterestRate, numPeriods, isInterestOnly)

  let outstandingPrincipal = initialPrincipal

  const data = {
    [startDate]: {
      date: startDate,
      period: 0,
      interest: 0,
      principal: 0,
      monthlyPayment: 0,
      outstandingPrincial: initialPrincipal
    }
  }

  for (let i = 1; i <= numPeriods; i++) {
    let { interest, principal } = computeMortgageSplits(outstandingPrincipal, monthlyPayment, periodInterestRate)

    outstandingPrincipal -= principal

    // If this is the end of an interest only loan, we must pay back the full principal
    if (i === numPeriods && isInterestOnly) {
      outstandingPrincipal -= initialPrincipal
      principal = initialPrincipal
      monthlyPayment += initialPrincipal
    }

    const date = moment(startDate).add(i, 'months').format('YYYY-MM-DD')

    data[date] = {
      date,
      period: i,
      interest,
      principal,
      monthlyPayment,
      outstandingPrincipal
    }
  }

  return data
}

import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const GenericModal = props => {
  const { close, children, header, isOpen } = props

  return (
    <div>
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close}>{header}</ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
      </Modal>
    </div>
  )
}

GenericModal.propTypes = {
  close: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export default GenericModal

import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { Container } from 'reactstrap'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import Navbar from './components/Navbar'
import firebase from './lib/firebase'
import useAuth from './hooks/auth'

import './App.css'
import OptionsScreen from './screens/Options'
import PortfolioScreen from './screens/Portfolio'

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID

  ]

}

const App = () => {
  const { user } = useAuth()

  if (!user) {
    return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  }

  return (
    <Router>
      <Navbar />
      <Container style={{ marginBottom: '2rem', marginTop: '4rem' }} fluid>
        <Switch>
          <Route path='/' exact component={PortfolioScreen} />
          <Route path='/options' exact component={OptionsScreen} />
          <Redirect to='/' />

        </Switch>
      </Container>
    </Router>
  )
}

export default App

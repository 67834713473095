import { useEffect, useState } from 'react'

import firebase from '../lib/firebase'

const useAuth = () => {
  const [state, setState] = useState(() => {
    const user = firebase.auth().currentUser

    return {
      user
    }
  })

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => setState({ user }))

    return () => unsubscribe()
  }, [])

  return state
}

export default useAuth

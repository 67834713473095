import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { v4 } from 'uuid'

import useAuth from '../hooks/auth'
import Modal from './GenericModal'
import Input from './Input'
import { firestoreCollections } from '../lib/firebase'

const uuid = v4

const AddAccountModal = ({ accounts, addAccount, close, isOpen }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [name, setName] = useState('')

  const isDisabled = !name

  const closeModal = () => {
    clearData()
    close()
  }

  const clearData = () => {
    setIsSaving(false)
    setName('')
  }

  const { user } = useAuth()

  const createAccount = async () => {
    if (isDisabled || !user) {
      return
    }

    setIsSaving(true)

    const matchQuery = await firestoreCollections.accounts
      .where('name', '==', name)
      .where('userId', '==', user.uid)
      .get()

    if (matchQuery.docs.length) {
      window.alert('That account already exists!')
      return
    }

    const data = {
      name,
      id: uuid(),
      userId: user.uid
    }

    await firestoreCollections.accounts.doc(data.id).set(data)

    await addAccount(data)
    closeModal()
  }

  return (
    <Modal close={close} header='Add an Account' isOpen={isOpen}>
      <Input
        label='Name of account'
        required
        value={name}
        onChange={setName}
        disabled={isSaving}
      />
      <Button
        disabled={isDisabled || isSaving}
        style={{ marginTop: '1rem' }}
        onClick={createAccount}
      >
        Save
      </Button>
    </Modal>
  )
}

export default AddAccountModal

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { debounce as lodashDebounce } from 'lodash'

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap'

const InputComponent = props => {
  const {
    label,
    helpText,
    value,
    onChange: propsOnChange,
    validators,
    formatter,
    unformatter,
    suffix,
    prefix,
    skipFormatOnDelete,
    debounce
  } = props

  const createFormattedValue = newValue =>
    formatter(newValue) ? prefix + formatter(newValue) + suffix : ''

  const [error, setError] = useState('')
  const [formattedValue, setFormattedValue] = useState(createFormattedValue(value))

  useEffect(() => {
    setFormattedValue(createFormattedValue(value))
  }, [value])

  const debouncedCallback = useRef(
    lodashDebounce(callback => {
      callback()
    }, debounce)
  )

  const onChange = e => {
    setError('')
    const newValue = e.target.value

    const unformattedNewValue = unformatter(newValue)

    // If we have done a delete and we skip format on delete, do nothing with the new value
    if (formattedValue.length > newValue.length && skipFormatOnDelete) {
      setFormattedValue(newValue)
    } else {
      let validatorError

      for (const validator of validators) {
        validatorError = validator(unformattedNewValue, label)

        if (validatorError) {
          setError(validatorError)
          break
        }
      }

      const newFormattedValue = createFormattedValue(unformattedNewValue)

      setFormattedValue(newValue)

      // Debounce if the number is > 0 since 0 debounce is the same thing as no debounce
      if (debounce) {
        debouncedCallback.current(() => {
          setFormattedValue(newFormattedValue)
          propsOnChange(unformattedNewValue, !validatorError)
        }, debounce)
      } else {
        setFormattedValue(newFormattedValue)
        propsOnChange(unformattedNewValue, !validatorError)
      }
    }
  }

  return (
    <FormGroup>
      <Label for={label}>{label}</Label>
      <Input
        name={label}
        type='text'
        value={formattedValue}
        onChange={onChange}
        invalid={Boolean(error)}
      />
      {error && (
        <FormFeedback>{error}</FormFeedback>
      )}
      {helpText && (
        <FormText>{helpText}</FormText>
      )}
    </FormGroup>
  )
}

InputComponent.propTypes = {
  debounce: PropTypes.oneOfType([PropTypes.number, null]),
  formatter: PropTypes.func,
  helpText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  unformatter: PropTypes.func,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validators: PropTypes.arrayOf(PropTypes.func)
}

InputComponent.defaultProps = {
  debounce: null,
  formatter: x => x,
  onChange: () => null,
  prefix: '',
  required: false,
  suffix: '',
  unformatter: x => x,
  value: '',
  validators: []
}

export default InputComponent
